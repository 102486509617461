import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventClickArg, EventInput } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import api from '../utils/api';
import DaysSlider from '../components/DaySlider';
import { BookLessonData } from '../model/BookLessonData';
import useScreenWidth from '../hooks/useScreenWidth';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';
import '../styles/calendarStyles.css';

interface CalendarProps {
  bookLessonData: BookLessonData;
  onDateSelect: (date: DateSelectArg) => void;
}

const MyCalendar: React.FC<CalendarProps> = ({ bookLessonData, onDateSelect }) => {
  const [availabilities, setAvailabilities] = useState<any[]>([]);
  const [events, setEvents] = useState<EventInput[]>([]);
  const [render, setRender] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const screenWidth = useScreenWidth();
  const { setLoading } = useLoading();
  const { setError } = useError();
  const calendarRef = useRef(null);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const today = new Date(Date.now());
  const start = new Date(new Date().setDate(today.getDate() - 30));
  const maxDate = new Date(new Date().setDate(today.getDate() + 30));

  useEffect(() => {
    console.log('In selectData booklessondata is: ', bookLessonData);
    setLoading(true);
    api
      .post('/availability/calendar', {
        student_id: bookLessonData.child.id,
        teacher_id: bookLessonData.teacher ? bookLessonData.teacher.id : undefined,
        subject_id: bookLessonData.subject.id,
        searched_lesson_length: bookLessonData.duration,
        location: bookLessonData.location,
      })
      .then((response) => {
        console.log('In selectData response is: ', response.data);
        const availabilities = response.data.availabilities;
        setAvailabilities(availabilities);
        const availableEvents = calculateAvailableTimes(availabilities);
        const unavailableEvents = calculateUnavailableTimes(availabilities);
        const events = availableEvents.concat(unavailableEvents);
        setEvents(events);
        setRender(true);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [bookLessonData]);

  useEffect(() => {
    setSelectedEventId(null); // Resetta l'evento selezionato quando la data cambia
  }, [selectedDate]);

  const calculateAvailableTimes = (availabilities) => {
    const availableEvents: EventInput[] = [];
    const stepMs = 30 * 60000;
    const lessonDurationMs = bookLessonData.duration * 3600000;

    availabilities.forEach((availability) => {
      const availableStart = new Date(availability.start_date_time).getTime();
      const availableEnd = new Date(availability.end_date_time).getTime();
      let currentTime = availableStart;

      while (currentTime < availableEnd) {
        const eventStart = new Date(currentTime);
        const displayEnd = new Date(currentTime + lessonDurationMs);
        const remainingTime = availableEnd - currentTime;

        if (remainingTime <= lessonDurationMs) {
          availableEvents.push({
            id: `${availability.id}-${currentTime}`,
            start: eventStart,
            end: new Date(availableEnd),
            display: 'block',
            classNames: ['cursor-pointer', 'bg-fpcred', 'bg-opacity-70', 'available-event'],
            editable: false,
            extendedProps: {
              displayStart: eventStart,
              displayEnd: new Date(availableEnd),
              eventType: 'available',
            },
          });
          break;
        } else {
          availableEvents.push({
            id: `${availability.id}-${currentTime}`,
            start: eventStart,
            end: new Date(currentTime + stepMs),
            display: 'block',
            classNames: ['cursor-pointer', 'bg-fpcred', 'bg-opacity-70', 'available-event'],
            editable: false,
            extendedProps: {
              displayStart: eventStart,
              displayEnd: displayEnd,
              eventType: 'available',
            },
          });
          currentTime += stepMs;
        }
      }
    });

    return availableEvents;
  };

  const calculateUnavailableTimes = (availabilities) => {
    const unavailableEvents = [];
    const startRange = start.getTime();
    const endRange = maxDate.getTime();

    const sortedAvailabilities = availabilities.sort(
      (a, b) => new Date(a.start_date_time).getTime() - new Date(b.start_date_time).getTime()
    );

    let lastEndTime = startRange;

    sortedAvailabilities.forEach((availability) => {
      const availableStart = new Date(availability.start_date_time).getTime();
      const availableEnd = new Date(availability.end_date_time).getTime();

      if (lastEndTime < availableStart) {
        unavailableEvents.push({
          start: new Date(lastEndTime),
          end: new Date(availableStart),
          display: 'background',
          classNames: ['stripe-background', 'cursor-not-allowed', 'select-none'],
          editable: false,
          extendedProps: {
            eventType: 'unavailable',
          },
        });
      }

      if (availableEnd > lastEndTime) {
        lastEndTime = availableEnd;
      }
    });

    if (lastEndTime < endRange) {
      unavailableEvents.push({
        start: new Date(lastEndTime),
        end: new Date(endRange),
        display: 'background',
        classNames: ['stripe-background', 'cursor-not-allowed', 'select-none'],
        editable: false,
        extendedProps: {
          eventType: 'unavailable',
        },
      });
    }

    return unavailableEvents;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date); // Aggiorna la data selezionata

    // Assicurati che il riferimento a FullCalendar esista prima di chiamare getApi
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date); // Vai alla nuova data
    } else {
      console.error('FullCalendar reference is null');
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    if (clickInfo.event.display === 'background') {
      return;
    }

    if (screenWidth < 1024) {
      // Solo su dispositivi mobili
      const eventId = clickInfo.event.id;

      if (selectedEventId === eventId) {
        // L'evento è già selezionato, quindi confermiamo la selezione
        const selectInfo: DateSelectArg = {
          start: clickInfo.event.start!,
          end: clickInfo.event.end!,
          startStr: clickInfo.event.start!.toISOString(),
          endStr: clickInfo.event.end!.toISOString(),
          allDay: clickInfo.event.allDay,
          view: clickInfo.view,
          jsEvent: clickInfo.jsEvent,
        };
        console.log('Selected event nel calendario:', selectInfo);
        onDateSelect(selectInfo);
        setSelectedEventId(null); // Resetta lo stato dopo la conferma
      } else {
        // Primo tocco, seleziona l'evento
        setSelectedEventId(eventId);
      }
    } else {
      // Comportamento normale su desktop
      const selectInfo: DateSelectArg = {
        start: clickInfo.event.start!,
        end: clickInfo.event.end!,
        startStr: clickInfo.event.start!.toISOString(),
        endStr: clickInfo.event.end!.toISOString(),
        allDay: clickInfo.event.allDay,
        view: clickInfo.view,
        jsEvent: clickInfo.jsEvent,
      };
      onDateSelect(selectInfo);
    }
  };

  const renderEventContent = (eventInfo) => {
    const { displayStart, displayEnd } = eventInfo.event.extendedProps;
    const displayStartTime = displayStart?.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    const displayEndTime = displayEnd?.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

    // Aggiungi una classe se l'evento è selezionato
    const isSelected = selectedEventId === eventInfo.event.id;
    if (eventInfo.event.extendedProps.eventType === 'available') {
      return (
        <div className={`available-event ${isSelected ? '' : ''}`}>
          <div className="font-bold">
            {displayStartTime} - {displayEndTime}
          </div>
          {isSelected && <div className="text-md font-bold text-white flex justify-end pr-2 pb-2">Conferma</div>}
        </div>
      );
    }
  };

  return (
    <>
      {/* Barra di navigazione delle date visibile solo su mobile */}
      {screenWidth < 1024 && <DaysSlider selectedDate={selectedDate} onDateChange={handleDateChange} />}

      {/* Calendario FullCalendar */}
      {render && events.length > 0 && (
        <FullCalendar
          key={screenWidth < 1024 ? 'mobile' : 'desktop'}
          locale={itLocale}
          ref={calendarRef}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView={screenWidth < 1024 ? 'timeGridDay' : 'timeGridWeek'}
          initialDate={selectedDate}
          // Mostra l'headerToolbar solo su desktop (>= 1024px)
          headerToolbar={
            screenWidth >= 1024
              ? {
                  start: 'title', // Mostra "Ottobre" su desktop
                  center: '',
                  end: 'prev,today,next',
                }
              : { start: 'title', center: '', end: '' }
          } // Nasconde l'headerToolbar su mobile
          titleFormat={{ month: 'long' }} // Mostra il nome del mese per l'header
          dayHeaderContent={
            screenWidth >= 1024
              ? (args) => {
                  const date = args.date;
                  const dayNumber = date.getDate();
                  const weekdayName = date.toLocaleDateString(undefined, { weekday: 'short' });
                  return (
                    <div className="custom-day-header p-2 rounded-full">
                      <div className="text-xl">{dayNumber}</div>
                      <div>{weekdayName}</div>
                    </div>
                  );
                }
              : false
          } // Nasconde il nome del giorno su mobile
          editable={false}
          selectable={true}
          selectAllow={(selectInfo) => {
            const calendarApi = calendarRef.current?.getApi();
            if (calendarApi) {
              const events = calendarApi.getEvents();
              for (let event of events) {
                if (event.extendedProps.eventType === 'unavailable') {
                  if (event.start < selectInfo.end && selectInfo.start < event.end) {
                    return false; // Prevent selection if it overlaps with an unavailable event
                  }
                }
              }
              return true;
            }
            return false; // Prevent selection if calendarApi is not available
          }}
          selectMirror={true}
          weekends={true}
          validRange={{ start: start, end: maxDate }}
          events={events}
          eventClick={handleEventClick}
          dateClick={(info) => {
            setSelectedDate(info.date);
            setSelectedEventId(null); // Resetta l'evento selezionato
          }}
          eventContent={renderEventContent}
          allDaySlot={false}
          height={'100%'}
          slotMinTime={'08:00:00'}
          slotMaxTime={'24:00:00'}
          scrollTime={'12:30:00'}
          scrollTimeReset={false}
        />
      )}
    </>
  );
};

export default MyCalendar;
