import React, { useEffect, useState } from 'react';
import Modal from '../../components/modal/Modal';
import withAuth from '../../utils/IsAuth';
import BookLesson from '../../components/BookLesson';
import api from '../../utils/api';
import { Subject } from '../../model/registration/SchoolSelection';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import { EventInput, EventClickArg } from '@fullcalendar/core';
import FamilyCalendar from '../../components/calendar/FamilyCalendar';
import EditFamilyLesson from '../../components/modal/EditFamilyLesson';
import EditBookedLesson from '../../components/EditBookedLesson';
import NotificationsBar from '../../components/NotificationsBar';

interface Props {}

interface Lesson {
  id: number;
  start_time: string;
  end_time: string;
  location: string;
  subject: Subject;
  teacher_id: number;
  student_id: number;
  size: number;
  status: string;
  lesson_order_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  student: {
    first_name: string;
    last_name: string;
  };
  teacher: {
    first_name: string;
    last_name: string;
  };
}

type ModalContent = 'BookLesson' | 'EditBookedLesson' | null;

const FamilyHome: React.FC<Props> = () => {
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [lessonsEvent, setLessonsEvent] = useState<EventInput[]>([]);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventInput | null>(null);
  const [modalContent, setModalContent] = useState<ModalContent>(null); // Contenuto della modale

  const openModal = () => {
    setModalContent('BookLesson');
    setIsOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setIsOpen(false);
  };

  const handleLessonClick = (lesson: Lesson) => {
    const lessonEvent = lessonsEvent.find((event) => event.id === lesson.id.toString());
    setSelectedEvent(lessonEvent);
    setEditModalOpened(true);
  };

  const closeLessionModal = () => {
    setEditModalOpened(false);
  };

  const handleEditModal = () => {
    closeLessionModal();
    setModalContent('EditBookedLesson');
    setIsOpen(true);
  };

  const openEditModal = (event: EventInput) => {
    setSelectedEvent(event);
    setModalContent('EditBookedLesson');
    setEditModalOpened(true);
  };

  useEffect(() => {
    setLoading(true);
    api
      .get('lesson', {
        params: {
          page: 1, // Prima pagina
          page_size: 100, // Numero di elementi per pagina
        },
      })
      .then((response) => {
        const res = response.data.lessons;
        // Filtra le lezioni con stato diverso da "deleted"
        const filteredLessons = res.filter(
          (lesson: Lesson) =>
            lesson.status.trim().toLowerCase() !== 'deleted' && lesson.status.trim().toLowerCase() !== 'placeholder'
        );
        setLessons(filteredLessons);
        const lessonsCalendar: EventInput[] = filteredLessons.map((lesson) => ({
          id: lesson.id.toString(),
          title: `${lesson.student.first_name} ${lesson.student.last_name}`,
          start: new Date(lesson.start_time),
          end: new Date(lesson.end_time),
          display: 'block',
          classNames: ['cursor-pointer', 'bg-green-200', 'border-green-500'],
          extendedProps: {
            student: lesson.student,
            teacher: lesson.teacher,
            subject: lesson.subject,
            location: lesson.location,
            metadata: lesson.metadata,
            customHtml: (
              <div className="flex flex-col text-black font-bold mt-1">
                <p>
                  {lesson.teacher.first_name} {lesson.teacher.last_name}
                </p>
                <p>
                  {lesson.subject.name} - {lesson.location}
                </p>
                <p>
                  {lesson.start_time.split(' ')[1].split(':')[0]}:{lesson.start_time.split(' ')[1].split(':')[1]} -{' '}
                  {lesson.end_time.split(' ')[1].split(':')[0]}:{lesson.end_time.split(' ')[1].split(':')[1]}
                </p>
              </div>
            ),
          },
        }));
        setLessonsEvent(lessonsCalendar);
        console.log('Lezioni', lessonsCalendar);
      })
      .catch((error) => {
        console.error('Error fetching lessons:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  return (
    <div className="flex flex-col w-full h-full max-h-[650px] md:max-h-[900px]">
      <div className="mb-2">
        <NotificationsBar page="Le tue lezioni" />
      </div>
      <div className="hidden lg:block p-2 sm:p-4 bg-white rounded-lg h-full">
        <div className="flex flex-col items-center justify-center gap-y-4 h-full">
          <div>
            <button className="py-2 px-3 bg-fpcred text-white rounded-lg" onClick={openModal}>
              Nuova lezione
            </button>
          </div>
          <div className="flex flex-col gap-y-3 w-full bg-white p-4 rounded-lg h-5/6">
            <FamilyCalendar lessonsEvent={lessonsEvent} isOpenEditModal={openEditModal} />
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <div className="max-w-5xl p-4 bg-white rounded-xl mx-2 sm:mx-auto">
          <div className="flex flex-col items-center justify-center gap-y-4">
            <div>
              <button className="py-2 px-4 bg-fpcred rounded-lg text-white font-bold" onClick={openModal}>
                Nuova lezione
              </button>
            </div>

            {lessons.map((lesson: Lesson, index: number) => {
              return (
                <div
                  key={index}
                  className="flex items-center bg-fpcwhite p-4 justify-between gap-4 rounded-lg shadow-lg w-full"
                  onClick={() => handleLessonClick(lesson)}>
                  <div className="font-bold text-fpcred">{lesson.start_time.split(' ')[0]}</div>
                  <div className=" flex flex-col">
                    <span className=" w-full text-center">{lesson.subject.name}</span>
                    <div className="flex flex-1 justify-center items-center">
                      <span>{lesson.location}</span>
                      <span>-</span>
                      <span>
                        {lesson.start_time.split(' ')[1].split(':')[0]}:{lesson.start_time.split(' ')[1].split(':')[1]}/
                        {lesson.end_time.split(' ')[1].split(':')[0]}:{lesson.end_time.split(' ')[1].split(':')[1]}
                      </span>
                    </div>
                  </div>
                  <div>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="5.5415" y="5.54167" width="9.5" height="1.58333" rx="0.791667" fill="black" />
                      <rect x="5.5415" y="10.2917" width="9.5" height="1.58333" rx="0.791667" fill="black" />
                    </svg>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {editModalOpened && (
        <EditFamilyLesson
          selectedEvent={selectedEvent}
          closeModal={closeLessionModal}
          handleEditModal={handleEditModal}
        />
      )}
      <Modal open={isOpen} closeModal={closeModal}>
        {modalContent === 'BookLesson' && <BookLesson closeModal={closeModal} />}
        {modalContent === 'EditBookedLesson' && selectedEvent && (
          <EditBookedLesson event={selectedEvent} closeModal={closeModal} />
        )}
      </Modal>
    </div>
  );
};

export default withAuth(FamilyHome);
