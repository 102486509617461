import React from 'react';
import Caricamento from '../ui/Caricamento.gif';

const Loading = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <img src={Caricamento} alt="Caricamento" className="w-48 h-48" />
    </div>
  );
};

export default Loading;
