import React, { useState, useEffect } from 'react';
import { Bell, BellDot } from 'lucide-react';
import { motion } from 'framer-motion';
import api from '../utils/api';
import BookLesson from './BookLesson';

interface Props {
  page: string;
}

interface Notification {
  id: number;
  type: string;
  sender_id: number;
  recipient_id: number;
  status: string;
  metadata: {
    new_lesson_id: number;
    old_lesson_id: number;
  };
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  accept_link: string | null;
  reject_link: string | null;
  notificationDetails?: {
    new_start_time: string;
    new_end_time: string;
    old_start_time: string;
    old_end_time: string;
  };
}

interface NotificationResponse {
  status: string;
  notifications: Notification[];
  pagination: {
    current_page: number;
    page_count: number;
    page_size: number;
    total: number;
  };
}

const GenericComponent: React.FC<Props> = ({ page }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // Stato per tracciare l'animazione in corso
  const [notifications, setNotifications] = useState<Notification[]>([]); // Stato per le notifiche
  const [isAccepted, setIsAccepted] = useState(false);
  const toggleExpansion = () => {
    if (isExpanded) {
      setIsAnimating(true); // Inizia l'animazione di chiusura
      setTimeout(() => setIsAnimating(false), 600); // Attendi la fine dell'animazione
    }
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    // Chiamata API per ottenere le notifiche
    api
      .get<NotificationResponse>('/notification')
      .then((response) => {
        console.log('le mie notifiche', response.data);
        setNotifications(response.data.notifications); // Salva le notifiche
        // Filtra le notifiche con metadati
        const notificationsWithMetadata = response.data.notifications.filter(
          (notification) =>
            notification.metadata && (notification.metadata.new_lesson_id || notification.metadata.old_lesson_id)
        );

        // Per ogni notifica con metadati, esegui la chiamata per ottenere i dettagli della lezione
        notificationsWithMetadata.forEach((notification) => {
          const lessonOldId = notification.metadata.old_lesson_id;
          const lessonNewId = notification.metadata.new_lesson_id;
          if (lessonOldId) {
            api
              .get(`/lesson/${lessonOldId}`)
              .then((lessonResponse) => {
                console.log(`Dettagli della vecchia lezione ${lessonOldId}:`, lessonResponse.data);

                setNotifications((prevNotifications) => {
                  const updatedNotifications = prevNotifications.map((notification) => {
                    if (notification.metadata && notification.metadata.old_lesson_id === lessonOldId) {
                      return {
                        ...notification,
                        notificationDetails: {
                          ...notification.notificationDetails,
                          old_start_time: lessonResponse.data.lesson.start_time,
                          old_end_time: lessonResponse.data.lesson.end_time,
                        },
                      };
                    }
                    return notification;
                  });

                  // Log dello stato aggiornato
                  console.log('Notifiche aggiornate con i dettagli della vecchia lezione:', updatedNotifications);
                  return updatedNotifications;
                });
              })
              .catch((error) => {
                console.error(`Errore nel recupero della lezione ${lessonOldId}`, error);
              });
          }

          if (lessonNewId) {
            api
              .get(`/lesson/${lessonNewId}`)
              .then((lessonResponse) => {
                console.log(`Dettagli della Nuova lezione ${lessonNewId}:`, lessonResponse.data);

                setNotifications((prevNotifications) => {
                  const updatedNotifications = prevNotifications.map((notification) => {
                    if (notification.metadata && notification.metadata.new_lesson_id === lessonNewId) {
                      return {
                        ...notification,
                        notificationDetails: {
                          ...notification.notificationDetails,
                          new_start_time: lessonResponse.data.lesson.start_time,
                          new_end_time: lessonResponse.data.lesson.end_time,
                        },
                      };
                    }
                    return notification;
                  });

                  // Log dello stato aggiornato
                  console.log('Notifiche aggiornate con i dettagli della nuova lezione:', updatedNotifications);
                  return updatedNotifications;
                });
              })
              .catch((error) => {
                console.error(`Errore nel recupero della lezione ${lessonNewId}`, error);
              });
          }
        });
      })
      .catch((error) => {
        console.error('Errore nel recupero delle notifiche', error);
      });
  }, []);

  useEffect(() => {
    if (isExpanded) {
      // Filtra le notifiche non lette
      const unreadNotifications = notifications.filter((notification) => notification.status === 'unread');

      // Esegui la chiamata API per ogni notifica non letta
      unreadNotifications.forEach((notification) => {
        api
          .post(`/notification/${notification.id}/read`)
          .then((response) => {
            console.log(`Notifica ${notification.id} segnata come letta`, response.data);
            // Aggiorna lo stato locale per segnare la notifica come letta
            setNotifications((prevNotifications) =>
              prevNotifications.map((n) => (n.id === notification.id ? { ...n, status: 'read' } : n))
            );
          })
          .catch((error) => {
            console.error(`Errore nel segnare la notifica ${notification.id} come letta`, error);
          });
      });
    }
  }, [isExpanded, notifications]);

  // Controlla se ci sono notifiche non lette
  const hasUnreadNotifications = notifications.some((notification) => notification.status === 'unread');

  // Funzione per ottenere il messaggio per ogni tipo di notifica
  const getNotificationMessage = (type: string) => {
    switch (type) {
      case 'new_single_lesson_booked_family':
        return 'Lezione prenotata con successo';
      case 'lesson_modified_same_teacher_family':
        return 'Lezione modificata con successo';
      case 'new_single_lesson_booked_teacher':
        return 'Lezione prenotata con successo';
      case 'lesson_modify_requested_from_teacher_family':
        return "l'insegnante ha richiesto una modifica della lezione";
      default:
        return 'Notifica sconosciuta'; // Fallback per tipi non gestiti
    }
  };

  return (
    <div className="relative">
      {/* Placeholder per mantenere il layout quando la barra è chiusa */}
      <motion.div
        style={{
          height: !isExpanded && !isAnimating ? '60px' : '60px', // Mantiene lo spazio solo quando la barra è chiusa
        }}
        className="transition-all duration-600 ease-in-out"></motion.div>

      {/* Barra Rossa */}
      <motion.div
        className="bg-fpcred p-4 rounded-md shadow-lg"
        initial={{ height: '60px' }}
        animate={{
          height: isExpanded ? '700px' : '60px', // Altezza dinamica
        }}
        style={{
          position: 'absolute', // Sempre assoluto per garantire sovraimpressione
          zIndex: 50,
          top: 0,
          left: 0,
          width: '100%',
          overflow: 'hidden',
        }}
        transition={{ duration: 0.6, ease: 'easeInOut' }}>
        {/* Contenuto dell'Header */}
        <div className="flex justify-between items-center">
          <h3 className="text-white font-bold">{page}</h3>
          <button onClick={toggleExpansion}>
            {/* Mostra BellDot se ci sono notifiche non lette */}
            {hasUnreadNotifications ? <BellDot className="text-yellow-500" /> : <Bell className="text-white" />}
          </button>
        </div>

        {/* Contenuto Espanso */}
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: isExpanded ? 1 : 0,
            height: isExpanded ? 'auto' : 0, // Altezza dinamica per nascondere il contenuto
          }}
          transition={{ duration: 0.4, ease: 'easeInOut' }}
          className="overflow-hidden">
          <div className="text-white mt-4 flex flex-col h-[600px]">
            <h2 className="text-lg font-bold mb-2">Le tue notifiche</h2>
            <div className="flex flex-col overflow-y-auto">
              {notifications.length > 0 ? (
                <ul>
                  {notifications.map((notification) => (
                    <li key={notification.id} className="bg-fpcwhite p-2 mb-2 text-black rounded-md">
                      {getNotificationMessage(notification.type)}
                      {notification.accept_link !== null &&
                        notification.reject_link !== null &&
                        notification.notificationDetails && (
                          <div className="flex gap-2 mt-2 justify-between">
                            <div className="flex flex-col text-black font-bold">
                              <div>
                                Data vecchia lezione:{' '}
                                {notification.notificationDetails.old_start_time
                                  ? `${new Date(notification.notificationDetails.old_start_time).toLocaleDateString(
                                      'it-IT',
                                      {
                                        day: 'numeric',
                                        month: 'long',
                                      }
                                    )} ${new Date(notification.notificationDetails.old_start_time).toLocaleTimeString(
                                      'it-IT',
                                      {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      }
                                    )}`
                                  : 'N/A'}{' '}
                                -{' '}
                                {notification.notificationDetails.old_end_time
                                  ? `${new Date(notification.notificationDetails.old_end_time).toLocaleTimeString(
                                      'it-IT',
                                      {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      }
                                    )}`
                                  : 'N/A'}
                              </div>
                              <div>
                                Data nuova lezione:{' '}
                                {notification.notificationDetails.new_start_time
                                  ? `${new Date(notification.notificationDetails.new_start_time).toLocaleDateString(
                                      'it-IT',
                                      {
                                        day: 'numeric',
                                        month: 'long',
                                      }
                                    )} ${new Date(notification.notificationDetails.new_start_time).toLocaleTimeString(
                                      'it-IT',
                                      {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      }
                                    )}`
                                  : 'N/A'}{' '}
                                -{' '}
                                {notification.notificationDetails.new_end_time
                                  ? ` ${new Date(notification.notificationDetails.new_end_time).toLocaleTimeString(
                                      'it-IT',
                                      {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      }
                                    )}`
                                  : 'N/A'}
                              </div>
                            </div>
                            <div className="flex flex-col md:flex-row items-center w-1/3 gap-4">
                              <button
                                className="bg-fpcpink text-fpcred font-bold shadow-md py-1 px-3 w-full h-full rounded hover:bg-gray-200 transition-colors"
                                onClick={() => {
                                  // Estrai l'ID dalla notifica e invia una richiesta all'API
                                  const modificationRequestId = new URL(notification.accept_link).searchParams.get(
                                    'lesson_modification_request_id'
                                  );
                                  if (modificationRequestId) {
                                    api
                                      .post(`/lesson/modify/confirm`, {
                                        lesson_modification_request_id: modificationRequestId,
                                      })
                                      .then(() => {
                                        console.log(
                                          `Modifica della lezione ${modificationRequestId} confermata con successo.`
                                        );
                                        setNotifications((prevNotifications) =>
                                          prevNotifications.map((n) =>
                                            n.id === notification.id ? { ...n, status: 'accepted' } : n
                                          )
                                        );
                                      })
                                      .catch((error) => {
                                        console.error(
                                          `Errore nella conferma della modifica ${modificationRequestId}`,
                                          error
                                        );
                                      });
                                  }
                                }}>
                                Accetta
                              </button>
                              {/* Pulsante Rifiuta */}
                              <button
                                className="bg-fpcred text-white font-bold shadow-md py-1 px-3 w-full h-full rounded-md hover:bg-red-700 transition-colors"
                                onClick={() => {
                                  // Estrai l'ID dalla notifica e invia una richiesta all'API di rifiuto
                                  const modificationRequestId = new URL(notification.reject_link).searchParams.get(
                                    'lesson_modification_request_id'
                                  );
                                  if (modificationRequestId) {
                                    api
                                      .post(`/lesson/modify/reject`, {
                                        lesson_modification_request_id: modificationRequestId,
                                      })
                                      .then(() => {
                                        console.log(
                                          `Modifica della lezione ${modificationRequestId} rifiutata con successo.`
                                        );
                                        // Aggiorna lo stato locale o mostra un feedback
                                      })
                                      .catch((error) => {
                                        console.error(
                                          `Errore nel rifiuto della modifica ${modificationRequestId}`,
                                          error
                                        );
                                      });
                                  }
                                }}>
                                Rifiuta
                              </button>
                            </div>
                          </div>
                        )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Non ci sono notifiche al momento.</p>
              )}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default GenericComponent;
