import React, { useEffect, useState } from 'react';
import { EventInput } from '@fullcalendar/core';
import EditBookedLessonTeacherCalendar from './calendar/EditBookedLessonTeacherCalendar';
import { ArrowBigRight } from 'lucide-react';
import api from '../utils/api';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';

interface EditBookedLessonTeacherProps {
  event: EventInput;
  closeModal: () => void;
}

type step = 'Calendar' | 'Confirm';

const EditBookedLessonTeacher: React.FC<EditBookedLessonTeacherProps> = ({ event, closeModal }) => {
  useEffect(() => {
    console.log(event);
  }, [event]);

  const dateStart = event.start as Date;
  console.log(dateStart);
  const dateEnd = event.end as Date;
  const [currentStep, setCurrentStep] = useState<step>('Calendar');
  const duration = (dateEnd.getTime() - dateStart.getTime()) / (1000 * 60 * 60);
  const [BookedLessonData, setBookedLessonData] = useState<EventInput>({
    id: event.id,
    location: event.extendedProps.location,
    start: dateStart,
    end: dateEnd,
    student_id: event.extendedProps.student.id,
    teacher_id: event.extendedProps.teacher.id,
    size: duration,
    subject_id: event.extendedProps.subject.id,
  });
  const { setLoading } = useLoading();
  const { setError } = useError();
  useEffect(() => {
    console.log('Dati della lezione:', BookedLessonData);
  }, [BookedLessonData]);

  const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
  const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };

  const handleSlotClick = (start: Date, end: Date) => {
    console.log('Cliccato:');
    setBookedLessonData((prev) => ({
      ...prev,
      start,
      end,
    }));
    setCurrentStep('Confirm');
  };

  const formatDateTime = (date: Date): string => {
    const pad = (n: number) => (n < 10 ? `0${n}` : n);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(
      date.getMinutes()
    )}:${pad(date.getSeconds())}`;
  };

  const confirmLessonUpdate = async () => {
    closeModal();
    setLoading(true);
    const formattedStart = formatDateTime(BookedLessonData.start as Date);
    try {
      const payload = {
        start_date_time: formattedStart,
        location: BookedLessonData.location,
        subject_id: BookedLessonData.subject_id,
        teacher_id: BookedLessonData.teacher_id,
        student_id: BookedLessonData.student_id,
        size: BookedLessonData.size,
      };
      console.log('payload per la modifica:', payload);
      const response = await api.put(`lesson/single/${event.id}`, payload);
      console.log('Lesson updated successfully:', response.data);
    } catch (error) {
      console.error(error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
    } finally {
      setLoading(false);
      // window.location.reload();
    }
  };

  return (
    <div className="flex flex-col gap-y-4 h-full">
      {currentStep === 'Calendar' && (
        <>
          <div className="bg-fpcwhite p-4 flex flex-col w-full shadow-md">
            <p>
              Studente: {event.extendedProps.student.first_name} {event.extendedProps.student.last_name}
            </p>
            <p>Materia: {event.extendedProps.subject.name}</p>
            <p>
              Giorno: {dateStart.toLocaleDateString('it-iT', dateOptions)} dalle{' '}
              {dateStart.toLocaleTimeString(undefined, timeOptions)} alle{' '}
              {dateEnd.toLocaleTimeString(undefined, timeOptions)}
            </p>
          </div>
          <div className="flex flex-col h-full">
            <EditBookedLessonTeacherCalendar event={BookedLessonData} handleSlotClick={handleSlotClick} />
          </div>
        </>
      )}
      {currentStep === 'Confirm' && (
        <div className="bg-fpcwhite p-4 flex flex-col w-full shadow-md">
          <p>
            Studente: {event.extendedProps.student.first_name} {event.extendedProps.student.last_name}
          </p>
          <p>Materia: {event.extendedProps.subject.name}</p>
          <div className="flex gap-4">
            <p>
              Giorno: {dateStart.toLocaleDateString('it-iT', dateOptions)} dalle{' '}
              {dateStart.toLocaleTimeString(undefined, timeOptions)} alle{' '}
              {dateEnd.toLocaleTimeString(undefined, timeOptions)}
            </p>
            <ArrowBigRight size={24} />
            <p>
              Nuovo Giorno: {BookedLessonData.start.toLocaleString('it-iT', dateOptions)} dalle{' '}
              {BookedLessonData.start.toLocaleString(undefined, timeOptions)} alle{' '}
              {BookedLessonData.end.toLocaleString(undefined, timeOptions)}
            </p>
          </div>
          <div className="flex gap-4 justify-between">
            <button
              onClick={() => {
                setCurrentStep('Calendar');
              }}
              className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2">
              Indietro
            </button>
            <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2" onClick={confirmLessonUpdate}>
              Conferma
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBookedLessonTeacher;
