import React, { useEffect } from 'react';
import api from '../../utils/api';
import { Subject } from '../../model/registration/SchoolSelection';
import { ProfileComponent } from '../../pages/family/Profile';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import { User } from 'lucide-react';
interface AddFamilyMemberProps {
  goTo: (component: ProfileComponent) => void;
}
export interface FamilyMember {
  id: number;
  first_name: string;
  last_name: string;
  school_class: string;
  school_level: string;
  school_name: string;
  subjects: Subject[];
}
const AddFamilyMember: React.FC<AddFamilyMemberProps> = ({ goTo }) => {
  const [familyMember, setFamilyMember] = React.useState<FamilyMember[]>([]);
  const { setLoading } = useLoading();
  const { setError } = useError();

  useEffect(() => {
    // setLoading(true);
    api
      .get('/family')
      .then((response) => {
        setFamilyMember(response.data.sons);
        console.log('Figli:', response.data.sons);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  return (
    <div className="flex w-full flex-col gap-4">
      {familyMember.map((member) => {
        return (
          <div key={member.id} className="flex p-4 bg-fpcwhite rounded-lg shadow-md ">
            <div className="flex flex-col justify-center items-center w-1/2 ">
              <User className="w-2/3 h-2/3 " />
            </div>
            <div className="w-1/2 flex flex-col justify-center items-center ">
              <h1 className="text:lg md:text-xl font-bold text-fpcred text-center">
                {member.first_name} {member.last_name}
              </h1>
              <h2 className="text-md md:text-lg text-black text-center">
                {member.school_name} - {member.school_class}
              </h2>
              <h2 className="text-md md:text-lg text-black text-center">
                {member.subjects.map((subject) => subject.name).join(', ')}
              </h2>
            </div>
          </div>
        );
      })}
      <div className="flex justify-center">
        <button className="p-2 bg-fpcred text-md font-bold text-white rounded-lg">Aggiungi un familiare</button>
      </div>
    </div>
  );
};

export default AddFamilyMember;
