import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';
import { EventInput } from '@fullcalendar/core';
import Calendario from './Calendar';
import { BookLessonData } from '../model/BookLessonData';
import { BadgeEuro, Calendar, Clock, MapPin, User, ArrowBigLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import { ReactComponent as Avatar } from '../ui/Avatar.svg';
interface EditBookedLessonProps {
  event: EventInput;
  closeModal: () => void;
}

type Steps = 'selectTeacher' | 'selectDate' | 'selectAvailableTeacher' | 'confirmFavoriteTeacher';

const EditBookedLesson: React.FC<EditBookedLessonProps> = ({ event, closeModal }) => {
  const lessonStart = event.start as Date;
  const lessonEnd = event.end as Date;

  // Calcolo della durata in ore
  const duration = (lessonEnd.getTime() - lessonStart.getTime()) / (1000 * 60 * 60);

  const bookLessonData: BookLessonData = {
    child: event.extendedProps.student,
    subject: event.extendedProps.subject,
    duration: duration,
    location: event.extendedProps.location,
    teacher: event.extendedProps.teacher, // Teacher preso dall'evento inizialmente
    date: {
      start: lessonStart,
      end: lessonEnd,
      startStr: lessonStart.toLocaleString('sv-SE', { timeZone: 'Europe/Rome' }).replace(' ', 'T'), // Formattazione ISO-like senza UTC
      endStr: lessonEnd.toLocaleString('sv-SE', { timeZone: 'Europe/Rome' }).replace(' ', 'T'),
      allDay: false,
      view: {} as any,
      jsEvent: {} as any,
    },
  };

  const [step, setStep] = useState<Steps>('selectTeacher');
  const [lessonData, setLessonData] = useState(bookLessonData);
  const [availableTeachers, setAvailableTeachers] = useState<any>({ exactAvailabilities: [], nearAvailabilities: [] });
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [teachersInfo, setTeachersInfo] = React.useState<any[]>(
    JSON.parse(localStorage.getItem('teachersInfo') || '[]')
  );

  useEffect(() => {
    switch (step) {
      case 'selectTeacher':
        console.log('sono in selectTeacher');
        console.log('bookLessonData:', bookLessonData);
        break;
      case 'selectDate':
        console.log('sono in selectDate');
        break;
      case 'selectAvailableTeacher':
        console.log('sono in selectAvailableTeacher');
        console.log('bookLessonData dopo il calendario:', lessonData);
        fetchAvailableTeachersForSelectedDate(lessonData.date.startStr, lessonData.date.endStr);
        break;
      case 'confirmFavoriteTeacher':
        console.log('sono dentro favoriteTeacher');
        break;
    }
  }, [step]);

  function goBack() {
    switch (step) {
      case 'selectDate':
        setStep('selectTeacher');
        break;
      case 'confirmFavoriteTeacher':
        setStep('selectDate');
        break;
      case 'selectAvailableTeacher':
        setStep('selectDate');
        break;
    }
  }

  // step === 'selectTeacher'
  const selectCurrentTeacher = () => {
    setLessonData((prevData) => ({ ...prevData, teacher: bookLessonData.teacher }));
    setStep('selectDate');
  };

  const selectOtherTeachers = () => {
    setLessonData((prevData) => ({ ...prevData, teacher: undefined })); // Nessuna preferenza
    setStep('selectDate');
  };

  const handleDateSelect = (selectInfo: any) => {
    const selectedDateStart = new Date(selectInfo.startStr);
    console.log('selectedDateStart:', selectedDateStart);
    const selectedDateEnd = new Date(selectInfo.startStr);
    console.log('selectedDateEnd:', selectedDateEnd);

    selectedDateEnd.setMinutes(selectedDateEnd.getMinutes() + bookLessonData.duration * 60);

    // Ensure the selected dates are in local time
    const selectedDateStartLocal = new Date(
      selectedDateStart.getTime() - selectedDateStart.getTimezoneOffset() * 60000
    );
    const selectedDateEndLocal = new Date(selectedDateEnd.getTime() - selectedDateEnd.getTimezoneOffset() * 60000);

    selectInfo.end = selectedDateEnd;
    selectInfo.endStr = selectedDateEndLocal.toISOString().slice(0, 19).replace('T', ' ');
    selectInfo.startStr = selectedDateStartLocal.toISOString().slice(0, 19).replace('T', ' ');

    setLessonData((prevData) => ({
      ...prevData,
      date: {
        start: selectedDateStart,
        end: selectedDateEnd,
        startStr: selectInfo.startStr,
        endStr: selectInfo.endStr,
        allDay: selectInfo.allDay,
        jsEvent: selectInfo.jsEvent,
        view: selectInfo.view,
      },
    }));
    setStep('selectAvailableTeacher');

    console.log('selectInfo: ', selectInfo);
    if (lessonData.teacher) {
      setStep('confirmFavoriteTeacher');
    } else {
      setStep('selectAvailableTeacher');
    }
  };

  const fetchAvailableTeachersForSelectedDate = async (startStr: string, endStr: string) => {
    setLoading(true);
    try {
      const availabilityRequest = {
        student_id: lessonData.child.id,
        teacher_id: lessonData.teacher ? lessonData.teacher.id : undefined,
        subject_id: lessonData.subject.id,
        location: lessonData.location,
        required_lessons: [{ start_date_time: startStr, end_date_time: endStr }],
      };

      const response = await api.post('availability/search', availabilityRequest);
      const availabilities = response.data.availabilities[0];
      console.log('availabilities: ', availabilities);

      // Carica i teacherInfo già memorizzati in localStorage
      const storedTeachersInfo = localStorage.getItem('teachersInfo');
      let teachersInfo = storedTeachersInfo ? JSON.parse(storedTeachersInfo) : [];

      // Combina le disponibilità esatte e vicine
      const allAvailabilities = [...availabilities.exact_availabilities, ...availabilities.near_availabilities];

      // Trova gli ID degli insegnanti unici
      const uniqueTeacherIdsSet = new Set(allAvailabilities.map((availability: any) => availability.teacher_id));
      const uniqueTeacherIds = Array.from(uniqueTeacherIdsSet);

      // Filtra gli ID degli insegnanti che non sono già memorizzati
      const missingTeacherIds = uniqueTeacherIds.filter(
        (teacherId) => !teachersInfo.some((teacher: any) => teacher.id === teacherId)
      );

      // Ottieni le informazioni degli insegnanti mancanti
      const newTeacherInfosPromises = missingTeacherIds.map(async (teacherId) => {
        const response = await api.get(`family/teacher/${teacherId}`);
        return response.data.teacher;
      });

      const newTeacherInfos = await Promise.all(newTeacherInfosPromises);

      // Aggiorna la lista degli insegnanti e memorizza in localStorage se necessario
      if (newTeacherInfos.length > 0) {
        teachersInfo = [...teachersInfo, ...newTeacherInfos];
        setTeachersInfo(teachersInfo);
        localStorage.setItem('teachersInfo', JSON.stringify(teachersInfo));
      }

      // Imposta gli insegnanti disponibili
      setAvailableTeachers({
        exactAvailabilities: availabilities.exact_availabilities,
        nearAvailabilities: availabilities.near_availabilities,
      });
    } catch (error) {
      console.error(error);
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
    }
  };

  const confirmLessonUpdate = async (selectedTeacher: any) => {
    closeModal();
    setLoading(true);
    try {
      const updatedLessonData = {
        ...lessonData,
        teacher: selectedTeacher,
      };
      setLessonData(updatedLessonData);
      const payload = {
        start_date_time: updatedLessonData.date.startStr,
        location: updatedLessonData.location,
        subject_id: updatedLessonData.subject.id,
        teacher_id: updatedLessonData.teacher ? updatedLessonData.teacher.id : undefined,
        student_id: updatedLessonData.child.id,
        size: updatedLessonData.duration,
      };
      console.log('payload per la modifica:', payload);
      const response = await api.put(`lesson/single/${event.id}`, payload);
      console.log('Lesson updated successfully:', response.data);
      // Aggiungi qui eventuali azioni post-modifica, come una notifica di successo o il reindirizzamento
    } catch (error) {
      console.error(error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  function TeacherCard({ teacher, availability, isNearAvailability = false }) {
    console.log('teacher: ', teacher);
    return (
      <div className="flex flex-col border-2 rounded-lg p-2 bg-white md:max-w-96 hover:shadow-lg hover:border-fpcred transition-shadow duration-300">
        <div className="flex gap-2 min-w-72 max-h-72">
          <div className="flex flex-col justify-start w-full h-full min-[30%] max-[60%]">
            <div className="flex h-full">
              <User className="flex w-full h-full" />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <p className="text-xl font-semibold">{teacher.first_name}</p>
              <p className="text-xl font-semibold">{teacher.last_name}</p>
            </div>
            {isNearAvailability && (
              <div className="bg-[#f3e8e8] text-[#d10c33] font-bold px-4 py-2 text-center rounded-full text-sm">
                {new Date(availability.start_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                /
                {new Date(availability.end_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
            )}
            <div className="flex">
              <p className="text-base">{teacher.bio ? teacher.bio : 'Nessuna bio disponibile'}</p> {/* Mostra la bio */}
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => confirmLessonUpdate(teacher)}
                className="bg-[#d10c33] px-4 py-2 rounded-xl text-white hover:bg-[#d10c33]/90 transition-colors duration-300">
                Seleziona insegnante
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full overflow-auto">
      <h2 className="text-2xl font-semibold mb-2">Modifica lezione</h2>

      <div className="bg-gray-100 p-4 rounded-lg shadow mb-2">
        <div className="flex gap-4">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <span className="font-semibold">Materia:</span>
              <span>{lessonData.subject.name}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold">Luogo:</span>
              <span>{lessonData.location}</span>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <span className="font-semibold">Data:</span>
              <span>{lessonStart.toLocaleDateString()}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold">Orario:</span>
              <span>
                {lessonStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} -{' '}
                {lessonEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
            </div>
          </div>
        </div>
      </div>
      {step !== 'selectTeacher' && (
        <div className="w-12 h-12">
          <ArrowBigLeft className="cursor-pointer w-full h-full text-fpcred" onClick={goBack} />
        </div>
      )}
      {step === 'selectTeacher' && (
        <div>
          <h3 className="text-xl mb-2">Seleziona un'opzione per l'insegnante</h3>
          <div className="flex flex-col gap-4">
            <button
              onClick={selectCurrentTeacher}
              className="p-3 bg-green-500 text-white rounded-lg hover:bg-green-600">
              Mantieni insegnante attuale ({lessonData.teacher.first_name} {lessonData.teacher.last_name})
            </button>
            <button onClick={selectOtherTeachers} className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
              Nessuna preferenza
            </button>
          </div>
        </div>
      )}

      {step === 'selectDate' && (
        <div className="flex flex-col h-full">
          <Calendario bookLessonData={lessonData} onDateSelect={handleDateSelect} />
        </div>
      )}

      {step === 'confirmFavoriteTeacher' && (
        <div>
          <h1 className="text-3xl font-bold mb-8 text-center text-[#d10c33]">Conferma Prenotazione</h1>
          {/* Riepilogo Lezione (a sinistra) */}
          <div className="">
            <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
              <div className="bg-fpcwhite rounded-lg p-6 shadow-md flex gap-4">
                <div className="w-full">
                  <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Riepilogo Lezione</h2>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <Calendar className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{lessonData.date.start.toLocaleDateString('it-IT')}</span>
                      <span className="ml-2">
                        {lessonData.date.start
                          .toLocaleDateString('it-IT', { weekday: 'long' })
                          .charAt(0)
                          .toUpperCase() +
                          lessonData.date.start.toLocaleDateString('it-IT', { weekday: 'long' }).slice(1)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>
                        {lessonData.date.start.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}/
                        {lessonData.date.end.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <MapPin className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{lessonData.location}</span>
                    </div>
                    <div className="flex items-center">
                      <BadgeEuro className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>
                        {lessonData.duration === 1 && '25 €'}
                        {lessonData.duration === 1.5 && '37,5 €'}
                        {lessonData.duration === 2 && '50 €'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" p-4 w-full">
                  <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Insegnante Preferito</h2>
                  <div className="flex">
                    <Avatar />
                    <div className="flex flex-col ml-4">
                      <p className="text-xl font-semibold">{lessonData.teacher.first_name}</p>
                      <p className="text-xl font-semibold">{lessonData.teacher.last_name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center mt-4">
                <button
                  className="bg-[#d10c33] px-4 py-2 rounded-xl text-white hover:bg-[#d10c33]/90 transition-colors duration-300"
                  onClick={() => confirmLessonUpdate(lessonData.teacher)}>
                  Conferma modifica
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}

      {step === 'selectAvailableTeacher' && availableTeachers && (
        <div className="w-full">
          <div className="bg-fpcwhite w-full h-full rounded-lg p-2 md:p-6 shadow-md">
            <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Insegnanti Disponibili</h2>
            <div className="flex flex-col h-full">
              <div>
                <h3 className="text-xl font-semibold mb-2">Disponibilità per l'orario richiesto</h3>
                <div className="flex max-h-96 overflow-x-auto gap-4">
                  {availableTeachers.exactAvailabilities.map((availability, index) => {
                    const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
                    return teacher ? <TeacherCard key={index} teacher={teacher} availability={availability} /> : null;
                  })}
                </div>
              </div>

              <div>
                <h3 className="text-xl font-semibold mb-2">Disponibilità vicino all'orario richiesto</h3>
                <div className="flex max-h-96 gap-4 overflow-x-auto">
                  {availableTeachers.nearAvailabilities.map((availability, index) => {
                    const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
                    return teacher ? (
                      <TeacherCard key={index} teacher={teacher} availability={availability} isNearAvailability />
                    ) : null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBookedLesson;
