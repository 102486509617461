import React from 'react';
import { TeacherForm } from '../../model/registration/TeacherForm';
import { City, Province } from '../../model/TeacherInfo';
import { VatType } from '../../model/VatType';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';
import { useEffect, useState } from 'react';
import { useError } from '../../context/ErrorContext';

interface Props {
  form: TeacherForm;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  errors: { [key: string]: string };
}

const InvoiceInfo: React.FC<Props> = ({ form, handleChange, errors }) => {
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [lang, setLang] = useState<string>('ita');
  const [contractData, setContractData] = useState<any>(null);
  const [showContractModal, setShowContractModal] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const handleCheckboxChange = () => {
    const newValue = !isChecked ? 1 : 0; // Usa un numero invece di una stringa
    console.log('Checkbox change:', newValue); // Debug
    setIsChecked(!isChecked);

    const mockEvent = {
      target: {
        name: 'terms_and_conditions',
        value: newValue, // Passa un numero
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;

    handleChange(mockEvent);
  };

  // Funzioni per aprire/chiudere la modale
  const openContractModal = () => setShowContractModal(true);
  const closeContractModal = () => setShowContractModal(false);

  useEffect(() => {
    setLoading(true);
    api
      .get('/province?page_size=200')
      .then((response) => {
        setProvinces(response.data.provinces);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    if (form.invoice_province_id) {
      setLoading(true);
      api
        .get(`/city?province_id=${form.invoice_province_id}&page_size=200`)
        .then((response) => {
          setCities(response.data.cities);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form.invoice_province_id, setLoading]);

  useEffect(() => {
    api
      .get(`/contract/cgc/teacher/${lang}`)
      .then((response) => {
        setContractData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [lang]);

  return (
    <div className="bg-white p-4 flex flex-col rounded-lg shadow-lg gap-4">
      <p className="text-center text-xl text-fpcred font-bold">Informazioni di fatturazione</p>
      <form className="flex flex-col gap-2">
        <div className="lg:flex-row flex flex-col gap-2 justify-between">
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Partita Iva</span>
            <input
              type="text"
              className={`border ${errors.vat ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="vat"
              value={form.vat}
              onChange={handleChange}
              maxLength={11}
            />
            {errors.vat && <span className="text-red-500">{errors.vat}</span>}
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Tipo partita Iva</span>
            <select
              className="border border-gray-400 rounded-lg p-2"
              name="vat_type"
              value={form.vat_type}
              onChange={handleChange}>
              <option value={VatType.FORFETTARIO}>Forfettario (15%)</option>
              <option value={VatType.FORFETTARIO_STARTUP}>Forfettario startup (5%)</option>
              <option value={VatType.ORDINARIO}>Ordinario</option>
              <option value={VatType.ALTRO}>Altro</option>
            </select>
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Codice destinatario</span>
            <input
              type="text"
              className={`border ${errors.destination_id ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="destination_id"
              value={form.destination_id}
              onChange={handleChange}
            />
            {errors.destination_id && <span className="text-red-500">{errors.destination_id}</span>}
          </label>
        </div>
        <div className="lg:flex-row flex flex-col gap-2">
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Provincia</span>
            <select
              name="invoice_province_id"
              value={form.invoice_province_id}
              onChange={handleChange}
              className="w-full border border-gray-400 rounded-lg px-2 text-lg py-2">
              <option value={0} disabled>
                Seleziona una provincia
              </option>
              {provinces.map((province) => (
                <option key={province.id} value={province.id}>
                  {province.name}
                </option>
              ))}
            </select>
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Città</span>
            <select
              disabled={cities.length === 0}
              name="invoice_city_id"
              value={form.invoice_city_id}
              onChange={handleChange}
              className="w-full border border-gray-400 rounded-lg px-2 text-lg py-2">
              <option value={0} disabled>
                Seleziona una città
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Cap</span>
            <input
              className="border border-gray-400 rounded-lg p-2"
              name="invoice_zip"
              value={form.invoice_zip}
              type="text"
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="flex gap-2">
          <label className="flex flex-col gap-2 w-3/4">
            <span className="font-bold text-xl sm:text-lg">Indirizzo</span>
            <input
              type="text"
              className="border border-gray-400 rounded-lg p-2"
              name="invoice_street_address"
              value={form.invoice_street_address}
              onChange={handleChange}
            />
          </label>
          <label className="flex flex-col gap-2 w-1/4">
            <span className="font-bold text-xl sm:text-lg">Civico</span>
            <input
              type="text"
              className="border border-gray-400 rounded-lg p-2"
              name="invoice_street_number"
              value={form.invoice_street_number}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="flex flex-col gap-2">
          <label className="flex flex-col gap-2 ">
            <span className="font-bold text-xl sm:text-lg">Iban</span>
            <input
              type="text"
              className={`border ${errors.iban ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="iban"
              value={form.iban}
              onChange={handleChange}
            />
            {errors.iban && <span className="text-red-500">{errors.iban}</span>}
          </label>
        </div>
      </form>
      {/* Disclaimer */}
      <div className="mb-4">
        <h3 className="font-semibold text-fpcred">Attenzione:</h3>
        <p>{contractData?.disclamer}</p>
      </div>
      <button
        className="bg-fpcpink hover:bg-fpcred text-fpcred hover:text-white font-bold py-2 px-4 rounded self-start"
        onClick={openContractModal}>
        Leggi il contratto
      </button>
      {/* Accettazione */}
      <div className="mb-4">
        <h3 className="font-semibold">Accettazione</h3>
        <p>{contractData?.acceptance}</p>
      </div>
      {/* CHECKBOX per accettare il contratto */}
      <label className="flex items-center gap-2">
        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
        <span>Accetto il contratto</span>
      </label>

      {/* --- MODALE CONTRATTO: viene visualizzata solo se showContractModal === true --- */}
      {showContractModal && contractData && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md w-11/12 max-w-2xl max-h-[80vh] overflow-y-auto">
            {/* Pulsante chiusura in alto a destra */}
            <div className="flex justify-end mb-4">
              <button onClick={closeContractModal} className="text-red-500 font-bold text-xl">
                X
              </button>
            </div>

            {/* Titolo del contratto */}
            <h1 className="text-2xl font-bold mb-2">{contractData.title}</h1>

            {/* Sezioni (e sottosezioni) */}
            {contractData.sections?.map((section: any, idx: number) => (
              <div key={idx} className="mb-4">
                <h3 className="font-bold mb-1">{section.title}</h3>
                <p>{section.content}</p>

                {section.subsections?.map((sub: any, sIdx: number) => (
                  <div key={sIdx} className="ml-4 mt-2 border-l pl-4 border-gray-300">
                    <h4 className="font-semibold">{sub.title}</h4>
                    <p>{sub.content}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceInfo;
