import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import AvailabilitiesCalendar from '../../components/calendar/AvailabilitiesCalendar';
import BookedLessonsCalendar from '../../components/calendar/BookedLessonsCalendar';
import { EventClickArg, EventInput } from '@fullcalendar/core';
import { AxiosResponse } from 'axios';
import withAuth from '../../utils/IsAuth';
import { Subject } from '../../model/registration/SchoolSelection';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import NotificationsBar from '../../components/NotificationsBar';
import EditTeacherLesson from '../../components/modal/EditTeacherLesson';
import Modal from '../../components/modal/Modal';
import EditBookedLessonTeacher from '../../components/EditBookedLessonTeacher';

interface Availabilities {
  id: number;
  start_date_time: string;
  end_date_time: string;
  group_available_id: number;
  locations: Locations;
  only_consecutive_lessons: boolean;
  teacher_id: number;
}

interface Locations {
  headquarter: LocationDetail;
  home: LocationDetail;
  home_from_headquarter: LocationDetail;
  online: LocationDetail;
}

interface LocationDetail {
  enabled: boolean;
  warning_time: number;
}

interface Lesson {
  id: number;
  start_time: string;
  end_time: string;
  lesson_order_id: number;
  location: 'headquarter' | 'home' | 'home_from_headquarter' | 'online';
  size: number;
  status: string;
  student: Student;
  teacher: Teacher;
  subject: Subject;
  guardian: Guardian;
  updated_at: string;
  created_at: string;
  deleted_at: string;
  metadata: {
    link: string;
    topics: string;
  };
}

interface Guardian {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
}
interface Student {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  school_class: string;
  school_level: string;
  school_name: string;
  subjects: Subject[];
}

interface Teacher {
  bio: null | string;
  cf: string;
  city: string;
  current_invoice_number: number;
  date_of_birth: string;
  default_warning_time_headquarter: number;
  default_warning_time_home: number;
  default_warning_time_online: number;
  destination_code: string;
  email: string;
  first_name: string;
  headquarter: null | any;
  home_lessons_radius_from_headquarter: number;
  home_lessons_radius_from_home: number;
  iban: string;
  id: number;
  image: null | any;
  invoice_enabled: boolean;
  invoice_prefix: string;
  last_name: string;
  legal_storage: number;
  phone: string;
  pi: string;
  province: string;
  receive_passive: number;
  reg_date: string;
  street_name: string;
  street_number: string;
  subjects: Subject[];
  terms_and_conditions: number;
  vat_type: string;
  zip: string;
}

const TeacherHome: React.FC = () => {
  const [mode, setMode] = useState<'availabilities' | 'bookedLessons'>('bookedLessons');
  const [availabilities, setAvailabilities] = useState<Availabilities[]>([]);
  const [availabilitiesEvent, setAvailabilitiesEvent] = useState<EventInput[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [lessonsEvent, setLessonsEvent] = useState<EventInput[]>([]);
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [selectedEvent, setSelectedEvent] = useState<EventInput | null>(null);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const openEditTeacherLesson = (event: EventInput) => {
    setSelectedEvent(event);
    setEditModalOpened(true);
  };

  const closeEditTeacherLesson = () => {
    setEditModalOpened(false);
  };

  const handleEditModal = () => {
    closeEditTeacherLesson();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    if (mode === 'availabilities') {
      api
        .post('availability/search', {
          n_weeks_visualized: 4,
        })
        .then((response) => {
          const res: Availabilities[] = response.data.availabilities;
          console.log(res);
          setAvailabilities(res);
          const availabilitiesCalendar: EventInput[] = res.map((availability) => ({
            id: availability.id.toString(),
            title: `Availability ${availability.id}`,
            start: new Date(availability.start_date_time),
            end: new Date(availability.end_date_time),
            display: 'block',
            classNames: ['cursor-pointer', 'bg-green-200', 'border-green-500', 'z-10'],
            editable: true,
            extendedProps: {
              locations: availability.locations,
              teacher_id: availability.teacher_id,
              group_available_id: availability.group_available_id,
              only_consecutive_lessons: availability.only_consecutive_lessons,
            },
          }));
          setAvailabilitiesEvent(availabilitiesCalendar);
        });
      api
        .get('lesson')
        .then((response) => {
          const res: Lesson[] = response.data.lessons;

          // Filtra le lezioni con stato diverso da "deleted"
          const filteredLessons = res.filter((lesson: Lesson) => {
            const status = lesson.status.trim().toLowerCase();
            return status === 'paid' || status === 'modify_request';
          });

          setLessons(filteredLessons);
          const lessonsCalendar: EventInput[] = filteredLessons.map((lesson) => ({
            id: lesson.id.toString(),
            title: 'Lezione',
            start: new Date(lesson.start_time),
            end: new Date(lesson.end_time),
            display: 'block',
            classNames: ['cursor-pointer', 'bg-gray-300', 'z-20'],
            extendedProps: {
              student: lesson.student,
              teacher: lesson.teacher,
              subject: lesson.subject,
              location: lesson.location,
            },
          }));
          setLessonsEvent(lessonsCalendar);
        })
        .catch((error) => {
          console.error('Error fetching availabilities:', error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (mode === 'bookedLessons') {
      api.get('lesson').then((response) => {
        const res: Lesson[] = response.data.lessons;

        // Filtra le lezioni con stato diverso da "deleted"
        const filteredLessons = res.filter((lesson: Lesson) => {
          const status = lesson.status.trim().toLowerCase();
          return status === 'paid' || status === 'modify_request';
        });

        // 2. “Pulisci” i dati per evitare metadata: null
        const cleanedLessons = filteredLessons.map((lesson) => {
          if (lesson.metadata == null) {
            return {
              ...lesson,
              metadata: {
                link: '',
                topics: '',
              },
            };
          }
          return lesson;
        });

        // 3. Salva le lezioni “pulite” nello stato
        setLessons(cleanedLessons);
        console.log('lezioni', cleanedLessons);

        const lessonsCalendar: EventInput[] = cleanedLessons.map((lesson) => ({
          id: lesson.id.toString(),
          title: 'Lezione',
          start: new Date(lesson.start_time),
          end: new Date(lesson.end_time),
          display: 'block',
          classNames: ['cursor-pointer', 'bg-fpcred'],
          extendedProps: {
            student: lesson.student,
            teacher: lesson.teacher,
            subject: lesson.subject,
            location: lesson.location,
            metadata: lesson.metadata,
            guardian: lesson.guardian,
          },
        }));
        setLessonsEvent(lessonsCalendar);
      });
      api
        .post('availability/search', {
          n_weeks_visualized: 4,
        })
        .then((response) => {
          const res: Availabilities[] = response.data.availabilities;
          setAvailabilities(res);
          const availabilitiesCalendar: EventInput[] = res.map((availability) => ({
            id: availability.id.toString(),
            title: `Availability ${availability.id}`,
            start: new Date(availability.start_date_time),
            end: new Date(availability.end_date_time),
            display: 'background',
            classNames: 'border-green-500',
            editable: true,
            extendedProps: {
              locations: availability.locations,
              teacher_id: availability.teacher_id,
              group_available_id: availability.group_available_id,
              only_consecutive_lessons: availability.only_consecutive_lessons,
              customHtml: (
                <div className="flex justify-center">
                  <p>Disponibilità</p>
                </div>
              ),
            },
          }));
          setAvailabilitiesEvent(availabilitiesCalendar);
        })
        .catch((error) => {
          console.error('Error fetching lessons:', error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [mode, setLoading]);

  const changeMode = () => {
    setMode((prev) => (prev === 'availabilities' ? 'bookedLessons' : 'availabilities'));
  };

  const formatDateForAPI = (date: Date): string => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  };

  const convertLocations = (locations: Locations) => {
    return Object.keys(locations).reduce((acc, key) => {
      acc[key] = {
        ...locations[key],
        enabled: Boolean(locations[key].enabled),
      };
      return acc;
    }, {} as Locations);
  };

  function editAvailability(selectedEvent: EventInput): Promise<AxiosResponse<any, any>> {
    const formattedStart = formatDateForAPI(new Date(selectedEvent.start as string));
    const formattedEnd = formatDateForAPI(new Date(selectedEvent.end as string));

    return api
      .put(`availability/${selectedEvent.id}`, {
        start_date_time: formattedStart,
        end_date_time: formattedEnd,
        locations: convertLocations(selectedEvent.extendedProps.locations),
        teacher_id: selectedEvent.extendedProps.teacher_id,
        group_available_id: selectedEvent.extendedProps.group_available_id,
        only_consecutive_lessons: selectedEvent.extendedProps.only_consecutive_lessons,
      })
      .then((response) => {
        const updatedAvailability = response.data.availabilities[0];
        setAvailabilities((prevAvailabilities) => {
          return prevAvailabilities.map((availability) =>
            availability.id === updatedAvailability.id ? updatedAvailability : availability
          );
        });
        return response;
      });
  }

  function deleteAvailability(id: number): Promise<void> {
    setLoading(true);
    return api
      .delete(`availability/${id}`)
      .then(() => {
        setAvailabilities((prevAvailabilities) => {
          return prevAvailabilities.filter((availability) => availability.id !== id);
        });
      })
      .catch((error) => {
        console.error('Error deleting availability:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function createAvailability(availability: any): Promise<AxiosResponse<any, any>> {
    const formattedStart = formatDateForAPI(new Date(availability.start));
    const formattedEnd = formatDateForAPI(new Date(availability.end));
    setLoading(true);
    return api
      .post('availability', {
        ...availability,
        start_date_time: formattedStart,
        end_date_time: formattedEnd,
      })
      .then((response) => {
        const newAvailability = response.data.availabilities[0];
        setAvailabilities((prevAvailabilities) => [...prevAvailabilities, newAvailability]);
        return response;
      })
      .catch((error) => {
        console.error('Error creating availability:', error);
        setError(true, error.response.data.message, error.response.status);
        return error;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="flex flex-col h-full max-h-[750px] md:max-h-[900px] gap-y-2">
      <div>
        <NotificationsBar page="Le mie lezioni" />
      </div>
      <div className="flex items-center justify-center">
        <div className="flex w-full md:w-2/3 gap-2 bg-white p-2 rounded-lg">
          <div className="w-full flex">
            <button
              className={`p-2 rounded-lg w-full ${
                mode === 'bookedLessons'
                  ? 'bg-fpcpink text-fpcred font-bold'
                  : 'bg-fpcwhite text-gray-600 shadow-md font-bold'
              }`}
              onClick={changeMode}>
              Calendario Lezioni
            </button>
          </div>
          <div className="w-full flex">
            <button
              className={`px-2 md:p-2 rounded-lg w-full ${
                mode === 'availabilities'
                  ? 'bg-fpcred text-white font-bold'
                  : 'bg-fpcwhite text-gray-600 shadow-md font-bold'
              }`}
              onClick={changeMode}>
              Calendario Disponibilità
            </button>
          </div>
        </div>
      </div>
      <div className="h-full bg-white p-2 sm:p-4 rounded-lg">
        {mode === 'availabilities' && (
          <AvailabilitiesCalendar
            lessonEvent={lessonsEvent}
            availabilitiesEvent={availabilitiesEvent}
            editAvailability={editAvailability}
            deleteAvailability={deleteAvailability}
            createAvailability={createAvailability}
          />
        )}
        {mode === 'bookedLessons' && (
          <BookedLessonsCalendar
            lessonsEvent={lessonsEvent}
            availabilitiesEvent={availabilitiesEvent}
            isOpenEditTeacherLesson={openEditTeacherLesson}
          />
        )}
      </div>
      {editModalOpened && selectedEvent && (
        <EditTeacherLesson
          selectedEvent={selectedEvent}
          closeModal={closeEditTeacherLesson}
          handleEditModal={handleEditModal}
        />
      )}
      <Modal open={isOpen} closeModal={closeModal}>
        <EditBookedLessonTeacher event={selectedEvent} closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default withAuth(TeacherHome);
