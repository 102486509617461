import React, { useEffect, useState } from 'react';
import { EventInput } from '@fullcalendar/core';
import { use } from 'i18next';
import { useLoading } from '../../context/LoadingContext';
import api from '../../utils/api';
import { useError } from '../../context/ErrorContext';

interface Props {
  selectedEvent: EventInput;
  closeModal: () => void;
  handleEditModal: () => void;
}

const EditFamilyLesson: React.FC<Props> = ({ selectedEvent, closeModal, handleEditModal }) => {
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [argomenti, setArgomenti] = useState(selectedEvent.extendedProps.metadata?.topics || '');

  if (!selectedEvent || !selectedEvent.extendedProps) {
    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center">
        <div className="bg-white p-4 mx-auto w-96 h-48 rounded-lg shadow-lg flex flex-col">
          <div className="flex justify-between">
            <h1 className="text-fpcred font-bold text-xl">Errore</h1>
            <button onClick={closeModal}>
              <p className="text-fpcred font-bold text-xl">X</p>
            </button>
          </div>
          <div className="flex flex-1 flex-col justify-center items-center">
            <p className="text-red-500">Errore nel caricare i dettagli della lezione.</p>
          </div>
        </div>
      </div>
    );
  }

  const { teacher, subject } = selectedEvent.extendedProps;
  // Utilizziamo selectedEvent.start, che è un oggetto Date
  const startDate = selectedEvent.start as Date;

  // Otteniamo solo la data in formato locale
  const dateOnly = startDate.toLocaleDateString();

  // Calcolare la differenza di tempo tra l'orario attuale e l'orario di selectedEvent
  const currentDate = new Date();
  const diffTime = startDate.getTime() - currentDate.getTime();
  const diffHours = diffTime / (1000 * 3600); // Converti la differenza in ore

  // Disabilita i bottoni se la differenza è inferiore a 48 ore
  const isDisabled = diffHours < 48;

  const handleSaveTopics = async () => {
    try {
      setLoading(true);
      // API specifica per salvare il link della lezione
      await api.post(`lesson/${selectedEvent.id}/topics`, {
        topics: argomenti,
      });
    } catch (error) {
      console.error('Errore durante il salvataggio del link', error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status || 500);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAndClose = async () => {
    if (argomenti.trim() !== '') {
      try {
        await handleSaveTopics();
      } catch (error) {
        console.error('Errore durante il salvataggio del link', error);
        // Se vuoi, potresti non chiudere la modale se avviene un errore
        return;
      }
    }

    // Se il link è vuoto o il salvataggio è andato a buon fine, chiudi la modale
    closeModal();
    window.location.reload();
  };

  const handleDelete = async () => {
    closeModal();
    try {
      setLoading(true);
      await api.delete(`lesson/${selectedEvent.id}`);
    } catch (error) {
      console.error("Errore durante l'eliminazione della lezione", error);
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center">
      <div className="bg-white p-4 gap-2 mx-auto w-96 rounded-lg shadow-lg flex flex-col">
        <div className="flex justify-between">
          <h1 className="text-fpcred font-bold text-xl">Informazioni sulla lezione</h1>
          <button onClick={handleSaveAndClose}>
            <p className="text-fpcred font-bold text-xl">X</p>
          </button>
        </div>
        <div className="flex flex-1 flex-col">
          <p className="font-bold ">{dateOnly}</p>
          <p>Figlio: {selectedEvent.title}</p>
          <p>
            Insegnante: {teacher.first_name} {teacher.last_name}
          </p>
          <p>Materia: {subject.name}</p>
          {selectedEvent.extendedProps?.metadata?.link && (
            <a
              href={selectedEvent.extendedProps.metadata.link}
              className="font-bold"
              target="_blank"
              rel="noopener noreferrer">
              Link: {selectedEvent.extendedProps.metadata.link}
            </a>
          )}
          <div>
            <label className="block font-bold">Argomenti:</label>
            <textarea
              className="border p-2 w-full"
              maxLength={1000}
              rows={5}
              value={argomenti}
              onChange={(e) => {
                setArgomenti(e.target.value);
              }}
              placeholder="Scrivi gli argomenti della lezione..."
            />
          </div>
        </div>
        <div className="flex justify-center gap-4">
          <button
            className={`bg-fpcred text-white font-bold rounded-lg w-1/3 p-2 ${
              isDisabled ? 'opacity-30 cursor-not-allowed' : ''
            }`}
            onClick={handleEditModal}
            disabled={isDisabled}>
            Modifica
          </button>
          <button
            className={`bg-fpcred text-white font-bold rounded-lg w-1/3 p-2 ${
              isDisabled ? 'opacity-30 cursor-not-allowed' : ''
            }`}
            onClick={handleDelete}
            disabled={isDisabled}>
            Cancella
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFamilyLesson;
