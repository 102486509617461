import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

const Success: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleGoToLessons = () => {
    navigate('/family/Home');
  };

  return (
    <div className="text-center p-4 bg-fpcwhite rounded-md shadow-md">
      <h1 className="text-2xl font-bold text-fpcred">Pagamento completato con successo!</h1>

      <p className="text-lg mt-4">
        Grazie! Il tuo ordine è stato elaborato con successo. <br />
      </p>
      <div className="flex justify-center w-full p-4">
        <button onClick={handleGoToLessons} className="bg-fpcred rounded-md px-4 py-2 font-bold text-white shadow-md">
          Torna alla pagina delle tue lezioni
        </button>
      </div>
    </div>
  );
};

export default Success;
