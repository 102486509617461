import React from 'react';
import { useState, useEffect } from 'react';
import { EventInput } from '@fullcalendar/core';
import { useError } from '../../context/ErrorContext';
import { useLoading } from '../../context/LoadingContext';
import api from '../../utils/api';
interface Props {
  selectedEvent: EventInput;
  closeModal: () => void;
  handleEditModal: () => void;
}

const EditTeacherLesson: React.FC<Props> = ({ selectedEvent, closeModal, handleEditModal }) => {
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [meetLink, setMeetLink] = useState(selectedEvent.extendedProps.metadata?.link || '');

  const handleSaveLink = async () => {
    try {
      setLoading(true);
      // API specifica per salvare il link della lezione
      await api.post(`lesson/${selectedEvent.id}/link`, {
        link: meetLink,
      });
    } catch (error) {
      console.error('Errore durante il salvataggio del link', error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status || 500);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAndMaybeSave = async () => {
    if (meetLink.trim() !== '') {
      // Se il link non è vuoto, prova a salvare
      try {
        await handleSaveLink();
      } catch (error) {
        console.error('Errore durante il salvataggio del link', error);
        // Se vuoi, potresti non chiudere la modale se avviene un errore
        return;
      }
    }

    // Se il link è vuoto o il salvataggio è andato a buon fine, chiudi la modale
    closeModal();
  };

  const handleDelete = async () => {
    closeModal(); // Chiude sempre la modale prima di fare qualsiasi altra cosa
    try {
      setLoading(true); // Imposta lo stato di caricamento
      await api.delete(`lesson/${selectedEvent.id}`); // Effettua la chiamata API
    } catch (error) {
      console.error("Errore durante l'eliminazione della lezione", error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status || 500); // Gestione errore
    } finally {
      setLoading(false); // Rimuove lo stato di caricamento
      //window.location.reload();
    }
  };

  useEffect(() => {
    console.log("L'evento selezionato:", selectedEvent);
  }, [selectedEvent]);

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center">
      <div className="bg-white p-4 mx-auto w-96 rounded-lg shadow-lg flex flex-col gap-2">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <h2 className="text-fpcred font-bold text-xl">Informazioni sulla lezione</h2>
            <h2>
              {/* {selectedEvent.start.toLocaleDateString('it-IT', {
                day: '2-digit',
                month: 'long',
              })} */}
            </h2>
          </div>
          <button onClick={handleCloseAndMaybeSave}>
            <p className="text-fpcred font-bold text-xl">X</p>
          </button>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex gap-2">
            <p className="font-bold">Responsabile:</p>
            <p>
              {selectedEvent.extendedProps.guardian.first_name} {selectedEvent.extendedProps.guardian.last_name}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="font-bold">Telefono:</p>
            <p>{selectedEvent.extendedProps.guardian.phone}</p>
          </div>
          <div className="flex gap-2">
            <p className="font-bold">Studente:</p>
            <p className="">
              {selectedEvent.extendedProps.student.first_name} {selectedEvent.extendedProps.student.last_name}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="font-bold">Materia:</p>
            <p>{selectedEvent.extendedProps.subject.name}</p>
          </div>
          <div className="flex gap-2">
            <p className="font-bold">Luogo:</p>
            <p>{selectedEvent.extendedProps.location}</p>
          </div>
          {selectedEvent.extendedProps.metadata.topics && (
            <div className="flex gap-2">
              <p className="font-bold">Argomenti:</p>
              <p>{selectedEvent.extendedProps.metadata.topics}</p>
            </div>
          )}
          <div className="flex gap-2 items-center">
            <p className="font-bold">Link:</p>
            <input
              type="url"
              className="px-4 h-8 py-2 rounded-md border-2 border-gray-500 w-full"
              value={meetLink}
              onChange={(e) => setMeetLink(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-center gap-4">
          <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2" onClick={handleEditModal}>
            Modifica
          </button>
          <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2" onClick={handleDelete}>
            Cancella
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTeacherLesson;
